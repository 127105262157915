<template>
  <div class="issueLeft_wrap">
    <!-- 详情信息 -->
    <div class="goosdDetailForm_box">
      <el-tabs type="card">
        <el-tab-pane label="产品详情">
            <div class="goodsDetail">
                <div class="lable">*详情图片:</div>
                <div class="main">
                    <div class="imageDetail">
                        <div class="swiper-image-wrap" v-for="(item,index) in goodsDetailForm.goodsDetail" :key="index">
                          <el-image class="iamge-item" :src="item.url"></el-image>
                        </div>
                        <el-button @click="uploadInfoImage">上传详情图片</el-button>
                    </div>
                    <div class="isShowInfo" v-if="isShowDetail">请选择图片</div>
                </div>
            </div>
        </el-tab-pane>
        <el-tab-pane label="配件">
            <div class="goodsDetail">
                <div class="lable">
                  <el-button @click="uploadMountingsImage">选择配件</el-button>
                </div>
                <div class="main">
                    <div class="imageDetail">
                        <div class="swiper-image-wrap" v-for="(item,index) in goodsDetailForm.accessoriesImgIdList" :key="index">
                          <el-image class="iamge-item" :src="item.imageUrlList[0].url"></el-image>
                          <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-tab-pane>
        <el-tab-pane label="规格性能">
          <div class="goodsDetail goodsSpe" style="height: 100%">
            <div class="text_content">
              <div class="lable">文案内容</div>
              <div class="main">
                <div class="header-ac">机构及工艺特性
                  <div class="biao">Structure process characteristics</div>
                </div>
                <div v-for="(item, index) in goodsDetailForm.textForm.contentTextList" :key="index">
                  <div class="u-f-ac">
                    <el-input v-model="item.value" placeholder="请在此处输入文案"
                      class="noBorder" size="mini" @input="copyChange()"></el-input>
                    <div class="del-ajc" @click="delText(index)">x</div>
                  </div>
                </div>
                <div class="u-f-ajc">
                  <div class="add-ajc" @click="addText">+</div>
                </div>
              </div>
            </div>
            <div class="text_content">
              <div class="lable">表格内容</div>
              <div class="main">
                <div class="header-ac">
                  产品性能 <div class="biao">Structure process characteristics</div>
                </div>
                <div class="contens-ac"
                  v-for="(item, index) in goodsDetailForm.tableForm.contentTableList"
                  :key="index">
                  <el-input placeholder="编辑文案" size="mini" v-model="item.property"
                    class="noBorder inputStyle" style="width: 150px" clearable @input="titleChange">
                  </el-input>
                  <el-input class="noBorder inputStyle" placeholder="编辑文案"
                    size="mini" style="width: 300px" v-model="item.perfomance" clearable @input="titleChange">
                  </el-input>
                  <div class="del-ajc" @click="delTable(index)">x</div>
                </div>
                <div class="u-f-ajc">
                  <div class="add-ajc" @click="addTable">+</div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="产品图纸">
          <div class="goodsDetail goods_drawing">
            <div class="lable">配置图片</div>
            <div class="main">
                <div class="swiper-image-wrap" v-for="(item,index) in goodsDetailForm.drawingFrom" :key="index">
                  <el-image class="iamge-item" :src="item.url"></el-image>
                </div>
              <el-button @click="uploadDrawingImage">上传产品图纸</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog title="选择配件" :visible.sync="dialogTableVisibles" width="75%" class="table-wraps">
      <div class="table-wra">
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" height="360"
              style="width: 100%"  @selection-change="handleSelectionChange" :row-key="getRowKeyOfTarget">
              <el-table-column type="selection" reserve-selection width="55"></el-table-column>
              <el-table-column prop="accessoriesNo" label="配件编号"></el-table-column>
              <el-table-column label="配件图片" width="120">
                <template slot-scope="scope">
                  <img :src="scope.row.imageUrlList?scope.row.imageUrlList[0].url: ''" alt="" width="60" height="40" />
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="name" label="配件名称"></el-table-column>
              <el-table-column prop="price" label="价格(元)" sortable></el-table-column>
              <el-table-column prop="inventory" label="库存" sortable>
                <template slot-scope="scope">
                  <div>{{scope.row.inventory}} {{scope.row.units}}</div>
                </template>
              </el-table-column>
          </el-table>
      </div>
      <div class="page-wrap">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currpage"
              :page-sizes="[5, 10, 20, 50,100]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalRow">
          </el-pagination>
      </div>
      <div class="btn-wrap">
          <el-button type="primary" @click="clickChooses">确定</el-button>
          <el-button @click="toggleSelection()">取消</el-button>
      </div>
    </el-dialog>
    
    <!-- 选择视频图片弹窗 -->
    <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
  </div>
</template>
<script>
import uploadData from '@/components/uploadData'
export default {
  components:{
      uploadData
  },
  data() {
    return {
      goodsDetailForm: {
        goodsDetail: [],  //详情图
        productDetailImgIdList: [],
        textForm: {       // 文案
          contentTextList: [{value: ""}],
        },
        tableForm: {    //表格
          contentTableList: [{ property: "", perfomance: "" }],
        },
        drawingFrom: [],    //图纸
        productDrawingIdList: [],
        // mountingsList: [],  //配件
        accessoriesImgIdList: [],
      },
      graphics: 100,  //上传图片数量
      uploadType: '',  //上传图片类型
      isShowDetail: false,
      tableData: [],
      totalRow: 0,
      pagesize: 5,
      currpage: 1,
      dialogTableVisibles: false,
      mountingsList: []
    };
  },
  mounted(){
    this.getAccessoriesList();
  },
  methods: {
    
    // 获取配件列表
    getAccessoriesList(){
      let params = {
        currentPage: this.currpage,
        pageSize: this.pagesize,
      }
      this.$API.getAccessoriesList(params).then( res => {
          if(res.code === 0){
            this.tableData = res.data.list;
            this.totalRow = res.data.pageInfo.totalRow;
          }
      })
    },
    //选中的值
    handleSelectionChange(val) {
        console.log(val);
        this.mountingsList = val;
    },
    //确定
    clickChooses(){
      console.log(this.mountingsList);
      this.$nextTick(()=>{
        this.goodsDetailForm.accessoriesImgIdList = this.mountingsList;
        this.dialogTableVisibles = false;
        this.$emit('DetailChang',this.goodsDetailForm)
      })
    },
    //取消
    toggleSelection() {
        this.$refs.multipleTable.clearSelection();
        this.dialogTableVisibles = false;
    },
    //父传子
    setValue(value){
      console.log("闲情",value);
      this.$nextTick(()=>{
        this.goodsDetailForm.goodsDetail = value.productDetailImgList;
        this.goodsDetailForm.productDetailImgIdList = value.productDetailImgIdList;

        this.goodsDetailForm.accessoriesImgIdList = value.accessoriesList;

        this.goodsDetailForm.drawingFrom = value.productDrawingList;
        this.goodsDetailForm.productDrawingIdList = value.productDrawingIdList;

        let text = value.specificationDetailsObj.copyContent;
        var result = text.map(o=>{
            return{
                value: o, 
            }
        });
        this.goodsDetailForm.textForm.contentTextList = result;

        let table = value.specificationDetailsObj.productPerfomances;
        this.goodsDetailForm.tableForm.contentTableList = table;

        this.$emit('DetailChang',this.goodsDetailForm)
      })
    },
    setIsShow(data){
      this.$nextTick(()=>{
        this.isShowDetail = data
      })
    },
    //选中图片
    selectImages(value,type){
      switch (type) {
        case 'info':
          this.goodsDetailForm.goodsDetail = value;
          break;
        case 'spec':
          this.goodsDetailForm.specDetail = value
          break;
        case 'drawing':
          this.goodsDetailForm.drawingFrom = value
          break;
        default:
          break;
      }
      this.$emit('DetailChang',this.goodsDetailForm)
    },

    handleRemove(){},
    //上传图纸
    uploadDrawingImage(){
      this.graphics = 20;
      this.uploadType = 'drawing'
      this.$refs.uploadData.getDate()
    },
    //上传规格图片
    uploadSpecImage(){
      this.graphics = 10;
      this.uploadType = 'spec'
      this.$refs.uploadData.getDate()
    },
    //上传配件图片
    uploadMountingsImage(){
      // this.graphics = 15;
      // this.uploadType = 'mountings'
      // this.$refs.uploadData.getDate()
      this.dialogTableVisibles = true
    },
    //上传详情图
    uploadInfoImage() {
        this.graphics = 15;
        this.uploadType = 'info'
        this.$refs.uploadData.getDate()
    },
    //输入文案内容触发事件
    copyChange(){
      this.$emit('DetailChang',this.goodsDetailForm)
    },
    //输入表格头
    titleChange(){
      this.$emit('DetailChang',this.goodsDetailForm)
    },
    //输入表格内容
    titleChange(){
      this.$emit('DetailChang',this.goodsDetailForm)
    },
    //添加文案内容
    addText() {
      let TextList = {
        value: "",
      };
      this.goodsDetailForm.textForm.contentTextList.push(TextList);
    },
    //删除文案内容
    delText(index) {
      this.goodsDetailForm.textForm.contentTextList.splice(index, 1);
    },
    //添加表格内容
    addTable() {
      let tableList = {
        property: "",
        perfomance: "",
      };
      this.goodsDetailForm.tableForm.contentTableList.push(tableList);
    },
    //删除表格
    delTable(index) {
      this.goodsDetailForm.tableForm.contentTableList.splice(index, 1);
    },
    handleCurrentChange(cpage) {
        this.currpage = cpage;
        this.getGoodsListbyBrand();
    },
    handleSizeChange(psize) {
        this.pagesize = psize;
        this.getGoodsListbyBrand()
    },
    getRowKeyOfTarget(row) {
        return row.id
    },
  },
};
</script>
<style lang="scss" >
.issueLeft_wrap {
    position: relative;
    .goosdDetailForm_box {
        height: calc(100vh - 220px);
        overflow: auto;
        background-color: #fff;
        .goodsDetail {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-left: 15px;
        .lable {
            font-size: 14px;
        }
        .main {
            margin-left: 10px;
            .isShowInfo{
                font-size: 12px;
                color: #F56C6C;
                padding-top: 5px;
            }
            .imageDetail {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .imageBorder {
                  margin-left: 10px;
                  margin-top: 10px;
                  width: 200px;
                  height: 150px;
                  color: rgb(189, 188, 188);
                  border: 1px dashed rgb(189, 188, 188);
                  border-radius: 6px;
                  font-size: 45px;
              }
              .swiper-image-wrap{
                display: inline;
                margin-right: 20px;
                padding-top: 20px;
                .iamge-item{
                  width: 100px;
                  height: 100px;
                  vertical-align: bottom;
                }
              } 
            }
            .tips {
              margin-top: 15px;
              font-size: 12px;
              color: rgb(150, 151, 153);
            }
        }
        }
        .goodsSpe {
            width: 100%;
            height: 100%;
            .text_content {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                .lable {
                    font-size: 14px;
                }
                .main {
                    border: 1px solid #eee;
                    width: 600px;
                    margin-left: 10px;
                    padding: 16px;
                    .header-ac {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .biao {
                            font-size: 12px;
                            margin-left: 10px;
                            color: rgb(153, 157, 158);
                        }
                    }
                    .u-f-ac{
                        padding-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .del-ajc{
                            cursor: pointer;
                            padding: 5px 20px;
                        }
                    }
                    .u-f-ajc{
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        .add-ajc{
                            cursor: pointer;
                            font-size: 24px;
                        }
                    }
                    .contens-ac{
                        display: flex;
                        flex-flow: wrap;
                        align-items: center;
                        padding-top: 10px;
                        .del-ajc{
                            cursor: pointer;
                            padding: 5px 20px;
                        }
                    }
                    .del {
                        margin-left: 10px;
                        width: 20px;
                        height: 20px;
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                .add {
                width: 30px;
                height: 30px;
                font-size: 23px;
                color: rgb(90, 90, 90);
                }
            }
            .image_content {
                display: flex;
                flex-flow: wrap;
                .lable {
                    font-size: 14px;
                }
                .main {
                    .item {
                        display: flex;
                        flex-wrap: wrap;
                        .imageBorder {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 130px;
                        height: 100px;
                        color: rgb(189, 188, 188);
                        border: 1px dashed rgb(189, 188, 188);
                        border-radius: 6px;
                        font-size: 45px;
                        }
                    }
                    .tips {
                        margin-top: 15px;
                        font-size: 12px;
                        color: rgb(150, 151, 153);
                    }
                }
            }
        }
        .goods_drawing {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            .lable {
                font-size: 14px;
            }
            .main {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .item {
                margin-left: 10px;
                margin-top: 10px;
                .imagBorder {
                    color: rgb(189, 188, 188);
                    font-size: 40px;
                    height: 80px;
                    width: 110px;
                    border-radius: 4px;
                    border: 1px dashed rgb(189, 188, 188);
                }
                .dInput {
                    margin-top: 5px;
                }
                }
                .tips {
                margin-top: 15px;
                font-size: 12px;
                color: rgb(150, 151, 153);
                }
                .swiper-image-wrap{
                  display: inline;
                  margin-right: 20px;
                  padding-top: 20px;
                  .iamge-item{
                    width: 100px;
                    height: 100px;
                    vertical-align: bottom;
                  }
                } 
            }
        }
    }
  .table-wraps{
    .page-wrap{
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
    }
    .btn-wrap{
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
    }
  }
}
</style>