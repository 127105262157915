<template>
    <contenWrap>
        <div class="goodsReleased">
            <div class="main_box">
                <div class="progress_box">
                    <div class="bar1 " :class="{ currentBar1: isBaseInfo == false }">1.编辑基本信息</div>
                    <div class="arrow" :class="{ currentArrow: isBaseInfo == false }"></div>
                    <div class="bar2" :class="{ currentBar2: isBaseInfo == false }">2.设置详情及其他分页信息</div>
                </div>
                <div class="textLable">
                    <div class="text">
                    {{ isBaseInfo == true ? "基本信息" : "详情信息" }}
                    </div>
                </div>
                <div class="issueInfo">
                    <!-- 左边 -->
                    <div class="left">
                        <div class="mian">
                            <!-- 基本信息 -->
                            <LeftIssueBase @dataChang="LeftIssueBaseDataChang" ref="LeftIssueBase" v-show="isBaseInfo"></LeftIssueBase>
                            <!-- 详情信息 -->
                            <div class="LeftIssueDetail" v-show="!isBaseInfo">
                                <LeftIssueDetail @DetailChang="LeftIssueDetailDataChang" ref="LeftIssueDetail" ></LeftIssueDetail>
                                <div class="btn-wraps">
                                    <el-button size="small" @click="chickNext">上一步</el-button>
                                    <el-button type="primary" size="small" @click="saveFormData">保存</el-button>
                                    <el-button type="info" size="small" @click="cancel" >取消</el-button >
                                    <el-button size="small" :disabled="true">放仓库</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 右边 -->
                    <div class="right">
                        <previewPage ref="previewPage"></previewPage>
                    </div>
                </div>
                <!-- 底部按钮 -->
                <div class="btn">
                    <!-- <el-button v-if="isBaseInfo" size="small" @click="chickNext">下一步</el-button> -->
                    <!-- <el-button v-if="!isBaseInfo" size="small" @click="chickNext">上一步</el-button> -->
                </div>
            </div>
        </div>
    </contenWrap>
</template>

<script>
import contenWrap from '@/components/contenWrap'
import pageMixin from '@/mixin/pageMixin'
import CreateBtn from '@/components/CreateBtn'
import LeftIssueBase from "./components/leftIssueBase";
import LeftIssueDetail from "./components/leftIssueDetail";
import previewPage from "./components/previewPage";
export default {
    mixins:[pageMixin],
    components: {
        contenWrap,
        CreateBtn,
        LeftIssueDetail,
        LeftIssueBase,
        previewPage
    },
    data() {
        return {
            isBaseInfo: true,
            baseInfo: [],
            detailInfo: [],
            id: null,
            isShowDetail: false
        }
    },
    mounted(){
      this.id = this.$route.query.id;
      if(this.id){
        this.getGoodsInfo();
      }
    },
    methods: {
        //获取商品信息
        getGoodsInfo(){
            let params = {
                id: this.id,
            }
            this.$API.getGoodsInfoPost(params).then( res => {
                console.log("商品信息",res.data);
                if(res.code === 0){
                    this.$nextTick(()=>{
                        this.$refs.LeftIssueBase.setValue(res.data);
                        this.$refs.LeftIssueDetail.setValue(res.data);
                        this.$refs.previewPage.setValue(res.data)
                    })
                }
            })
        },
        // 保存商品
        saveFormData(){
            let classValue = this.baseInfo.catalogId;
            let catalogId
            if(classValue){
                catalogId = classValue[classValue.length-1];    //所属分类id
            }
            let imageList = [];         //图片id集合
            let productDetailImg = [];         //详情图片id集合
            let accessoriesImg = null;         //配件图片id集合
            let copyContent = [];           //机构及工艺特性
            let productDrawing = null;         //产品图片id集合
            let accessories = [];        //配件id列表
            let productPerfomances =[];
            if(this.id){
                productDetailImg = this.detailInfo.productDetailImgIdList;
                // accessoriesImg = this.detailInfo.accessoriesImgIdList;
                productDrawing = this.detailInfo.productDrawingIdList;
            }
            if(this.baseInfo.goodsImages){
                if(this.id){
                    if(this.baseInfo.goodsImages[0].fileName){
                        this.baseInfo.goodsImages.forEach(v => {
                            imageList.push(v.id)
                        });
                    }
                    // imageList = this.baseInfo.goodsImages
                }else{
                    this.baseInfo.goodsImages.forEach(v => {
                        imageList.push(v.id)
                    });
                }
            }
            let ids = [];   
            if(this.baseInfo.metalsClass){
                if(this.id){
                    ids = this.baseInfo.metalsClass
                }else{
                    this.baseInfo.metalsClass.forEach(a => {
                        a.forEach(a1 =>{
                            ids.push(a1)
                        })
                    });
                }
            }    
            // accessories = [...new Set(ids)];        //配件id列表
            if(this.detailInfo.tableForm){
                productPerfomances = this.detailInfo.tableForm.contentTableList;
            }
            if(this.detailInfo.goodsDetail){
                this.detailInfo.goodsDetail.forEach(v => {
                    productDetailImg.push(v.id)
                });
            }
            if(this.detailInfo.accessoriesImgIdList){
                this.detailInfo.accessoriesImgIdList.forEach(v => {
                    accessoriesImg.push(v.id)
                });
        }
            if(this.detailInfo.textForm){
                this.detailInfo.textForm.contentTextList.forEach(v => {
                    copyContent.push(v.value)
                });
            }
            if(this.detailInfo.drawingFrom){
                this.detailInfo.drawingFrom.forEach(v => {
                    productDrawing.push(v.id)
                });
            }

            let params = {
                goodsNo: this.baseInfo.goodsNo,
                goodsName: this.baseInfo.goodsName,
                price: Number(this.baseInfo.price),
                type: 'brand',
                catalogId: catalogId,
                imageIds: imageList,
                inventory: Number(this.baseInfo.inventory),
                units: this.baseInfo.units,
                // accessories: ids,
                description: this.baseInfo.description,
                productDetailImg: productDetailImg,
                accessories: accessoriesImg,
                specificationDetails:{
                    copyContent: copyContent,
                    productPerfomances: productPerfomances
                },
                productDrawing: productDrawing,
                id: this.id

            }
            if(this.detailInfo.length != 0){
                if(this.id){
                    // 修改
                    this.$API.updateGoodsPost(params).then( res => {
                        if(res.code === 0){
                            this.$message.success("修改商品成功");
                            this.isShowDetail = false;
                            this.$router.push({ name: "productList"});
                        }
                    })
                }else{
                    // 新建
                    this.$API.addGoodsPost(params).then( res => {
                        if(res.code === 0){
                            this.$message.success("添加商品成功");
                            this.isShowDetail = false;
                            this.$router.push({ name: "productList"});
                        }
                    })
                }
                
            }else{
                this.isShowDetail = true;
                this.$nextTick(()=>{
                    this.$refs.LeftIssueDetail.setIsShow(this.isShowDetail);
                })
            }
        },
        //LeftIssueDetail值回调方法
        LeftIssueDetailDataChang(value){
            console.log("回传",value);
            this.$refs.previewPage.setDetail(value);
            this.detailInfo = value;
            // this.isBaseInfo = value.isBaseInfo;
        },
        //LeftIssueBase值回调方法
        LeftIssueBaseDataChang(value){
            this.$refs.previewPage.setValue(value);
            this.baseInfo = value;
            this.isBaseInfo = value.isBaseInfo;
        },
        chickNext() {
            this.isBaseInfo = !this.isBaseInfo;
        },
        cancel() {
            this.$router.go(-1);
        },
    }
}
</script>

<style lang="scss">
.goodsReleased{
    .main_box {
        margin: 0 10px;
        height: calc(100% - 80px);
        .progress_box {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            font-size: 14px;
            .bar1 {
                height: 36px;
                line-height: 36px;
                background-color: #155bd4;
                flex: 1;
                color: rgb(255, 255, 255);
                text-align: center;
            }
            .currentBar1 {
                background-color: #f7f8fa;
                color: rgb(51, 51, 51);
            }
            .arrow {
                width: 0;
                height: 0;
                border: 18px solid #155bd4;
                border-color: transparent transparent transparent #155bd4;
                background-color: #f7f8fa;
            }
            .currentArrow {
                border-color: transparent #155bd4 transparent transparent;
            }
            .bar2 {
                height: 36px;
                line-height: 36px;
                text-align: center;
                background-color: #f7f8fa;
                flex: 1;
                color: rgb(51, 51, 51);
            }
            .currentBar2 {
                background-color: #155bd4;
                color: rgb(255, 255, 255);
            }
            .mian {
                height: calc(100vh - 80px);
            }
        }
        .textLable {
            background-color: #fff;
        .text {
            margin-left: 10px;
            font-size: 12px;
            padding: 2px 5px;
            background-color: #f7f8fa;
        }
        }
        .issueInfo {
            height: calc(100vh - 220px);
            width: 100%;
            background: #fff;
            display: flex;
            justify-content: space-between;
            .left {
                height: 100%;
                width: 68%;
                .mian {
                    height: 100%;
                    width: 100%;
                    .LeftIssueDetail{
                        position: relative;
                        .btn-wraps{
                            position: absolute;
                            bottom: -50px;
                            left: 50%;
                        }
                    }
                }
            }
            .right {
                height: 100%;
                width: 32%;
                overflow-y: auto;
                
            }
        }
        .btn {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            .el-button{
                width: 80px;
            }
        }
    }
}
</style>