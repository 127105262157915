<template>
  <div class="issueRight_wrap">
    <!-- 基本信息 -->
    <div class="goosdBaseForm_box">
      <!-- 基础信息表单 -->
      <el-form ref="form" :rules="goosdBaseFormRules" :model="goosdBaseForm" label-width="80px">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="goosdBaseForm.goodsName" size="small" placeholder="请输入商品名称" 
                @input="inputChange" maxlength="10" show-word-limit
            ></el-input>
        </el-form-item>

        <el-form-item label="商品编号" prop="goodsNo">
          <el-input v-model="goosdBaseForm.goodsNo" size="small" placeholder="请输入商品编号" maxlength="10" show-word-limit></el-input>
          <div class="b-info">编号：数字+字母的格式</div>
        </el-form-item>

        <el-form-item label="商品描述"  prop="description">
          <el-input v-model="goosdBaseForm.description" size="small" placeholder="请输入商品描述" 
            @input="goodsChange" maxlength="60" show-word-limit></el-input>
          <div class="b-info">商品详情页标题下面展示， 微信分享时会显示，建议60字以内</div>
        </el-form-item>

        <el-form-item label="商品类目" prop="catalogId">
          <el-cascader
            :options="classOptions"
            v-model="goosdBaseForm.catalogId"
            :props="{ checkStrictly: true }"
            clearable
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品主图" prop="imageUrlList">
          <!--上传图片-->
          <div class="swiper-image-wrap" v-for="(item,index) in goosdBaseForm.imageUrlList" :key="index">
            <el-image class="iamge-item" :src="item.url"></el-image>
          </div>
          <el-button @click="uploadMainImage">选择图片</el-button>
          <div class="b-info">建议尺寸:300px*200px,比例为2:3;支持格式:jpg、png;最多5张，单图不超过1M。</div>
        </el-form-item>
        <!-- <el-form-item label="主图视频" >
          <div class="iconfont icon-shipin" >  
            <el-button @click="upload">上传视频</el-button>
          </div>
          <div class="b-info">
            建议尺寸:300px*200px,比例为2:3; 支持格式:mp4;
          </div>
        </el-form-item> -->
        <!-- <el-form-item label="颜色分类" prop="colorClass">
          <div class="color_box" style="margin-top: 10px">
            <div class="header">
              <div class="lable">颜色分类</div>
              <div class="lable">颜色展示图</div>
            </div>
            <div class="item" v-for="(item, index) in goosdBaseForm.colorClass" :key="index">
                <div class="colorName">
                    <el-select size="small"
                    v-model="item.colorNmae" filterable placeholder="请选择">
                    <el-option
                        v-for="item in colorOptions" :key="item.value" 
                        :label="item.label" :value="item.value">
                    </el-option>
                    </el-select>
                </div>
                <div class="colorImage">
                    <div @click="chooseImage(index)"  class="wrap-img">
                      <div v-if="item.colorImage == ''">选择图片</div>
                      <img v-else :src="item.colorImage" alt=""/>
                    </div>
                </div>
              <div class="delItem" @click="delItem(index)">刪除</div>
            </div>
          </div>
          <div class="u-f-ajc" >
            <div class="addColor " @click="addColor">+</div>
          </div>
        </el-form-item> -->
        <!-- <el-form-item label="五金配置" prop="metalsClass">
          <el-cascader
            v-model="goosdBaseForm.metalsClass"
            :options="metalsClassOptions"
            :props="{ multiple: true, checkStrictly: true }"
            size="small"
            clearable>
          </el-cascader>
        </el-form-item> -->

        <el-form-item label="商品价格" prop="price">
          <div class="u-f-ac">
            <el-input size="small" maxlength="8" show-word-limit
              v-model="goosdBaseForm.price" @input="priceChange"></el-input>
            <div class="unit">元</div>
          </div>
        </el-form-item>
        <el-form-item label="商品库存" prop="inventory">
          <div class="u-f-ac">
            <el-input v-model="goosdBaseForm.inventory" maxlength="8" show-word-limit
              onkeyup="value=value.replace(/[^\d]/g,'')"
              size="small"
            ></el-input>
            <div class="unit">
                <!-- <el-input v-model="goosdBaseForm.units" size="small" placeholder="输入单位"></el-input> -->
                <el-select v-model="goosdBaseForm.units" placeholder="请选择单位" size="small" style="width: 100px">
                  <el-option
                    v-for="item in unitsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-wrap-next">
      <el-button size="small" @click="clickNext">下一步</el-button>
    </div>
    <!-- 选择视频图片弹窗 -->
    <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
  </div>
</template>
<script>
import uploadData from '@/components/uploadData'
export default {
    components:{
        uploadData
    },
    data() {
    return {
      goosdBaseForm: {
        goodsName: "",
        goodsNo: "",
        description: "",
        catalogId: [],
        goodsImages: [],
        colorClass: [{ colorNmae: "", colorImage: "" }],
        metalsClass: [],
        inventory: "",
        price: null,
        units: '',
        imageUrlList: [],
        isBaseInfo: true
      },
      metalsClassOptions: [],
      unitsOptions:[{
        value: "件",
        label: "件",
      },{
        value: "扇",
        label: "扇",
      },{
        value: "块",
        label: "块",
      }],
      colorOptions: [{
          value: "选项1",
          label: "黄金糕",
        }],
        classOptions: [],

        videoFlag: false,
        //是否显示进度条
        videoUploadPercent: "",
        //进度条的进度，
        isShowUploadVideo: false,
        //显示上传按钮
        videoForm: {
            showVideoPath: ''
        },
        graphics: 100,  //上传图片数量
        uploadType: '',  //上传图片类型
        colorIndex: 0,
        //校验
        goosdBaseFormRules:{
          goodsName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
          ],
          goodsNo: [
            { required: true, message: '请输入商品编号', trigger: 'blur' },
          ],
          description: [
            { required: true, message: '请输入商品描述', trigger: 'blur' },
          ],
          catalogId: [
            { required: true, message: '请选择商品类目', trigger: 'change' },
          ],
          imageUrlList: [
            { required: true, message: '请选择商品主图', trigger: 'blur' },
          ],
          metalsClass: [
            { required: true, message: '请选择五金配置', trigger: 'change' },
          ],
          price: [
            { required: true, message: '请输入商品价格', trigger: 'blur' },
          ],
          inventory: [
            { required: true, message: '请输入商品库存', trigger: 'blur' },
          ],
        }
    };
  },
    mounted(){
      this.getListCatalogType();
      this.getListCatalog();
    },
    methods: {
      //父传子
      setValue(value){
        this.$nextTick(()=>{
          this.goosdBaseForm.goodsName = value.goodsName;
          this.goosdBaseForm.goodsNo = value.goodsNo;
          this.goosdBaseForm.description = value.description;
 
          let catalogIdList = [];
          catalogIdList.push(value.catalogId)
          this.goosdBaseForm.catalogId = catalogIdList;

          this.goosdBaseForm.goodsImages = value.imageUrlIdList;

          let list  = [];
          value.accessoriesList.forEach(v => {
            list.push(v)
          });
          this.goosdBaseForm.metalsClass = list;
          
          this.goosdBaseForm.inventory = value.inventory;
          this.goosdBaseForm.price = value.price;
          this.goosdBaseForm.units = value.units;
          // let imgList = [];
          // let data = value.imageUrlList
          // for(let i in data){
          //     imgList.push({'url': data[i]})
          // }
          this.goosdBaseForm.imageUrlList = value.imageUrlList;

          this.$emit('dataChang',this.goosdBaseForm)
        })
      },
      // 获取配件类目结构
      getListCatalog(){
        this.$API.getCatalogList().then( res => {
            if(res.code === 0){
                let resultSecond;
                //添加弹窗的选择
                var results = res.data.map(o=>{
                    if(o.childrenNode){
                        resultSecond =  o.childrenNode.map(c=>{
                            return{
                                value: c.data.id, 
                                label: c.data.classifyName,
                                createTime: c.data.createTime
                            }
                        })
                    }
                    return{
                        value: o.data.id, 
                        label: o.data.classifyName,
                        createTime: o.data.createTime,
                        children: resultSecond
                    }
                });
                this.metalsClassOptions = results;
            }
        })
      },
      // 获取商品类目结构
      getListCatalogType(){
        this.$API.getListCatalogType().then( res => {
            if(res.code === 0){
                let resultSecond;
                var result = res.data.map(o=>{
                    if(o.childrenNode){
                        resultSecond =  o.childrenNode.map(c=>{
                            return{
                                value: c.data.id, 
                                label: c.data.catalogName,
                            }
                        })
                    }
                    return{
                        value: o.data.id, 
                        label: o.data.catalogName,
                        children: resultSecond
                    }
                });
                this.classOptions = result;
            }
        })
      },
      //选中图片
      selectImages(value,type){
        switch (type) {
          case 'swiper':
            this.goosdBaseForm.goodsImages = value;
            this.goosdBaseForm.imageUrlList = value;
            break;
          case 'color':
            this.goosdBaseForm.colorClass[this.colorIndex].colorImage = value[0]
            break;
          default:
            break;
        }
        this.goosdBaseForm.isBaseInfo = true;
        this.$emit('dataChang',this.goosdBaseForm)
      },
      //颜色分类，选择图片
      chooseImage(index){
        this.colorIndex = index
        this.graphics = 1;
        this.uploadType = 'color';
        this.$refs.uploadData.getDate()
      },
      //上传主图
      uploadMainImage(){
        this.graphics = 5;
        this.uploadType = 'swiper'
        this.$refs.uploadData.getDate()
      },
      upload(){
          this.$refs.uploadData.getDate()
      },

      //价格修改
      priceChange(e){
          this.goosdBaseForm.price
          this.$emit('dataChang',this.goosdBaseForm)
      },
      //商品名称修改
      inputChange(e){
          this.goosdBaseForm.goodsName;
          this.goosdBaseForm.isBaseInfo = true;
          this.$emit('dataChang',this.goosdBaseForm)
      },
      //商品描述修改
      goodsChange(e){
          this.goosdBaseForm.description;
          this.goosdBaseForm.isBaseInfo = true;
          this.$emit('dataChang',this.goosdBaseForm)
      },
      getData(){
          return new Promise( async (resolve, reject) => {
              let vali = await this.$refs.form.validate().catch(err=>err)
              if(vali === true){
                  resolve(this.goosdBaseForm.name)
              }
              resolve(false)
          })
      },
      addColor() {
      let colorClass = {
          name: "",
          colorImage: "",
      };
      this.goosdBaseForm.colorClass.push(colorClass);
      },
      delItem(index) {
          this.goosdBaseForm.colorClass.splice(index, 1);
      },

      handleRemove(file, fileList) {
          console.log(file, fileList);
      },
      // 下一步校验
      clickNext(){
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.goosdBaseForm.isBaseInfo = false;
            this.$emit('dataChang',this.goosdBaseForm)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
  },
};
</script>
<style lang="scss" scoped>
.issueRight_wrap {
    position: relative;
    .goosdBaseForm_box {
        height: calc(100vh - 220px);
        overflow: auto;
        background-color: #fff;
        padding-left: 15px;
        .ivBorder {
            width: 150px;
            height: 100px;
            font-size: 45px;
            color: rgb(189, 188, 188);
            border: 1px dashed rgb(189, 188, 188);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .el-input{
            width: 400px;
        }
        .b-info {
            font-size: 12px;
            line-height: 26px;
            color: rgb(150, 151, 153);
        }
        .color_box {
            width: 560px;
            .header {
                font-size: 14px;
                background-color: #eee;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                display: flex;
                flex-flow: wrap;
                align-items: center;
                .lable {
                    width: 250px;
                    text-align: center;
                }
            }
            .item {
                font-size: 12px;
                background-color: rgb(235, 249, 250);
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .colorName {
                    width: 100%;
                    padding: 10px;
                }
                .colorImage {
                    color: rgb(90, 88, 211);
                    width: 280px;
                    img {
                        margin-left: 10px;
                        width: 60px;
                        height: 40px;
                    }
                    .wrap-img{
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                    }
                }
                .delItem {
                width: 80px;
                color: red;
                }
            }
        }
        .u-f-ajc{
            width: 560px;
            display: flex;
            justify-content: center;
            .addColor {
                margin-top: 10px;
                width: 40px;
                height: 40px;
                line-height: 35px;
                text-align: center;
                font-size: 30px;
                color: blueviolet;
                border-radius: 50%;
                background-color: antiquewhite;
            }
        }
        .u-f-ac{
            display: flex;
            flex-flow: wrap;
            .unit {
                padding-left: 10px;
            }
            .el-input{
                width: 120px;
            }
        }
        .btn {
            height: 30px;
            background-color: #f7f8fa;
        }
        .swiper-image-wrap{
          display: inline;
          margin-right: 20px;
          .iamge-item{
            width: 100px;
            height: 100px;
            vertical-align: bottom;
          }
        }   
    }
  .btn-wrap-next{
    position: absolute;
    bottom: -50px;
    left: 50%;
  }
}
</style>
